import './App.css';

function App() {
  return (
    <>
    <div style={{
      width: '100%',
      height: '100vh',
    }}>
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScJRCMhM7Qtl6zYWfo4d9Z2AFsHO4PI38j082tTIcUJ5Fmqgw/viewform?embedded=true" width="640" height="1121" frameborder="0" marginheight="0" marginwidth="0" title="Pratham Investments Form">Loading…</iframe>
      </div>
    </>
  );
}

export default App;
